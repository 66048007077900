// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-pnoe-coach-js": () => import("./../../../src/pages/pnoe-coach.js" /* webpackChunkName: "component---src-pages-pnoe-coach-js" */),
  "component---src-pages-the-science-js": () => import("./../../../src/pages/the-science.js" /* webpackChunkName: "component---src-pages-the-science-js" */),
  "component---src-pages-why-get-tested-js": () => import("./../../../src/pages/why-get-tested.js" /* webpackChunkName: "component---src-pages-why-get-tested-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

